import React from 'react'
import Helmet from 'react-helmet'
import {graphql} from 'gatsby'
import Layout from '../components/layout'
import SidebarNav from '../components/SidebarNav';


export const query = graphql`
    query(
        $slug: String!
    ) {
        markdownRemark (
            fields: {
                slug: {
                    eq: $slug
                }
            }
        ) {
            frontmatter {
                title
                content
                pathname
            }
            html
        }
    }
`

const CategoryTemplate = (props) => {

    return(
        <Layout>
            <Helmet
                title= {` خدمات مهاجرتی فرزان - ${props.data.markdownRemark.frontmatter.title}  `}
                meta={[
                    { name: 'description', content: ' دکتر فرزان سجودی وکیل با سابقه مهاجرت، همراه شما در مهاجرت به کانادا  ' }
                ]}
            >
            </Helmet>

            <div id="main" className="category-template">
                <SidebarNav pathname = {props.data.markdownRemark.frontmatter.pathname} />
                <section className="category-banner">
                    <div className="category-header1"> 
                        <h2>{props.data.markdownRemark.frontmatter.title}</h2>
                        <p>{props.data.markdownRemark.frontmatter.content}</p>
                        <a href="/immigrationForm" className="button special">فرم ارزیابی مهاجرت</a>
                    </div>
                </section>
                <div dangerouslySetInnerHTML={{ __html: props.data.markdownRemark.html }} /> 
             </div>

        </Layout>
    )
}

export default CategoryTemplate