import React , {useState , useEffect} from 'react'
import { Link } from 'gatsby'

const SidebarNav = (props) => {

    const paths = {
        entrepreneur : "entrepreneur",
        skillworker : "skillworker", 
        selfemployed : "selfemployed", 
        sponsorship : "sponsorship",
        studentvisa : "studentvisa",
        visitorvisa : "visitorvisa"
    }
    const titles = {
        entrepreneur : "کارآفرینی ",
        skillworker : "نیروی کار ماهر", 
        selfemployed : "خوداشتغالی", 
        sponsorship : "اسپانسرشیپ",
        studentvisa : "ویزای تحصیلی",
        visitorvisa : "ویزای توریستی"
    }

    return(
    <div className="category-nav">
        <ul className="alt">
            <li><Link to={paths.entrepreneur}
                className={`${paths.entrepreneur === props.pathname ? 'active' : ' ' }`}>{titles.entrepreneur} </Link></li>
            <li><Link to={paths.skillworker}
                className={`${paths.skillworker === props.pathname ? 'active' : ' ' }`}> {titles.skillworker}</Link></li>
            <li><Link to={paths.selfemployed}
                className={`${paths.selfemployed === props.pathname ? 'active' : ' ' }`}>{titles.selfemployed}</Link></li>
            <li><Link to={paths.sponsorship}
                className={`${paths.sponsorship === props.pathname ? 'active' : ' ' }`}>{titles.sponsorship}</Link></li>
            <li><Link to={paths.studentvisa}
                className={`${paths.studentvisa === props.pathname ? 'active' : ' ' }`}>{titles.studentvisa} </Link></li>
            <li><Link to={paths.visitorvisa}
                className={`${paths.visitorvisa === props.pathname ? 'active' : ' ' }`}>{titles.visitorvisa}</Link></li>
        </ul>
        <div className="contact-nav">
            <p>برای اطلاعات بیشتر  با ما تماس بگیرید.</p>
            <br />
            <p>۱&nbsp;۶۰۴۸۱۶۶۲۵۹</p>
            <p>۱&nbsp;۶۷۲۵۱۵۱۰۱۰</p>
            <Link className="button small" to="/#two">درخواست مشاوره</Link>
        </div>
    </div>

    )
}

export default SidebarNav;